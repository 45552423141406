import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

function CTA({}) {

  return (
    <div className="container mx-auto px-4 max-w-7xl relative z-20 pt-20 md:pt-28 lg:pt-40">
      
      <div className="rounded-2xl p-10 md:p-16 lg:p-20 bg-gray-100 text-center">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight text-zinc-900 mb-4 md:mb-5">Want to <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>work with me?</span></h2>
        <p className="text-lg sm:text-xl md:text-2xl text-zinc-500 mb-8 md:mb-10">
           Let me help you build something great
        </p>
        <Link to="/contact" className="text-white font-medium sm:text-base md:text-lg bg-zinc-900 rounded-full px-4 sm:px-6 py-2 sm:py-3 inline-flex items-center space-x-2 sm:space-x-3 hover:bg-black transition-colors group ">
        <span className="relative z-10">Get in touch</span>
            <svg className="fill-current w-5 h-5 transform transition-transform group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="30" height="30"
viewBox="0 0 30 30"><path d="M 22.990234 8.9902344 A 1.0001 1.0001 0 0 0 22.292969 10.707031 L 25.585938 14 L 2 14 A 1.0001 1.0001 0 1 0 2 16 L 25.585938 16 L 22.292969 19.292969 A 1.0001 1.0001 0 1 0 23.707031 20.707031 L 28.619141 15.794922 A 1.0001 1.0001 0 0 0 28.623047 14.207031 A 1.0001 1.0001 0 0 0 28.617188 14.203125 L 23.707031 9.2929688 A 1.0001 1.0001 0 0 0 22.990234 8.9902344 z"></path></svg>
      
      </Link>
      </div>
      
    </div>
  )
}


export default CTA
