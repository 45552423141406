import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

// import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import { motion } from "framer-motion"

const GoInsureMe = () => (
  <Layout>
    <SEO title="GoInsureMe - UK Based Freelance Web Designer/Developer" />
    <div className="container mx-auto px-4 max-w-7xl relative z-20">
      <div className="rounded-2xl overflow-hidden relative">
        <StaticImage className="absolute left-0 top-0 w-full h-full rounded-2xl overflow-hidden round-img z-10 work-hero" src="../images/goinsureme-thumb.jpg" alt="GoInsureMe" />
        <div className="w-full bg-zinc-900 bg-opacity-60 md:bg-opacity-20 bg-gradient-to-t from-zinc-900 to-transparent pt-24 md:pt-36 rounded-2xl relative z-20">
            <div className="p-8 md:p-12 lg:p-16 text-white  w-full">
                      <motion.h1 initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} className="text-5xl md:text-6xl flex flex-wrap lg:text-7xl md:max-w-sm lg:max-w-lg xl:max-w-xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tighter">GoInsureMe</motion.h1>

              <div className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-10 lg:gap-20 xl:gap-24 pt-6 md:pt-10">
          <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.075 } }} viewport={{ once: true }}>
            <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-3 md:mb-4">
                About the Client
            </div>
            <div className="text-base md:text-lg lg:text-xl lg:leading-normal text-white md:opacity-90 font-light">
              <p>
                An insurance broker providing an instant or scheduled callback from an insurance advisor to discuss no-obligation life insurance quotes
              </p>
            </div>
          </motion.div>
        </div>
            </div>
          </div>
      </div>
        
        
    </div>

    <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.125 } }} viewport={{ once: true }} className="relative z-20 pt-16 md:pt-24 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              GoInsureMe needed someone to take their idea from inception to a fully functioning website that could capture leads. After some in-depth discussions with the stakeholders, I went to work by conducting a competitor analysis, designing a logo and prototyping the landing page to capture leads. Once these were approved, I built out the website & integrated a lead capture form with custom callback date & time logic. 
            </p>
            <p>The website was built as a static site, simplifying deployment and hosting. I also added custom logic to only allow users from the UK to access the lead capture form, as required by the client.</p>
          </div>
          
        </div>
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-5">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700 mb-5 md:mb-6">
              <p>
               Web Design, Web Development, Logo Design
              </p>
            </div>
            <a className="flex items-center text-zinc-900 hover:underline font-medium p-4 rounded-xl bg-zinc-100 hover:bg-zinc-200 transition" href="https://goinsureme.co.uk" target="_blank">
              <svg className="fill-current  w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  width="50" height="50"

                  viewBox="0 0 50 50">
                  <path d="M 37 4.0039062 C 34.69525 4.0037812 32.389766 4.8822188 30.634766 6.6367188 L 25.634766 11.636719 C 23.142766 14.128719 22.430516 17.727281 23.478516 20.863281 L 27.070312 17.271484 C 27.220312 16.244484 27.673891 15.253844 28.462891 14.464844 L 33.462891 9.4648438 C 34.437891 8.4908438 35.72 8.0019531 37 8.0019531 C 38.28 8.0019531 39.561156 8.4898437 40.535156 9.4648438 C 42.484156 11.414844 42.484156 14.586156 40.535156 16.535156 L 35.535156 21.535156 C 34.746156 22.324156 33.756516 22.776734 32.728516 22.927734 L 29.134766 26.521484 C 30.062766 26.831484 31.029047 26.996094 31.998047 26.996094 C 34.303047 26.996094 36.608281 26.118281 38.363281 24.363281 L 43.363281 19.363281 C 46.873281 15.854281 46.872281 10.145719 43.363281 6.6367188 C 41.608281 4.8827187 39.30475 4.0040313 37 4.0039062 z M 30.960938 16.980469 A 2.0002 2.0002 0 0 0 29.585938 17.585938 L 17.585938 29.585938 A 2.0002 2.0002 0 1 0 20.414062 32.414062 L 32.414062 20.414062 A 2.0002 2.0002 0 0 0 30.960938 16.980469 z M 18.449219 23.023438 C 15.997141 22.898656 13.506469 23.765766 11.636719 25.634766 L 6.6367188 30.634766 C 3.1277188 34.143766 3.1277187 39.854281 6.6367188 43.363281 C 8.3917188 45.117281 10.696 45.994141 13 45.994141 C 15.304 45.994141 17.608281 45.116328 19.363281 43.361328 L 24.363281 38.361328 C 26.855281 35.869328 27.569484 32.270766 26.521484 29.134766 L 22.927734 32.728516 C 22.777734 33.755516 22.324156 34.746156 21.535156 35.535156 L 16.535156 40.535156 C 14.586156 42.485156 11.413844 42.485156 9.4648438 40.535156 C 7.5158438 38.585156 7.5158438 35.413844 9.4648438 33.464844 L 14.464844 28.464844 C 15.253844 27.675844 16.244484 27.221312 17.271484 27.070312 L 20.863281 23.478516 C 20.079281 23.216516 19.266578 23.065031 18.449219 23.023438 z"></path>
                  </svg>
                  Visit website
            </a>
          </div>
          
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-72 md:h-[800px] lg:h-[900px] xl:h-[1000px] col-span-8 lg:col-span-9 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/goinsureme-1.png" alt="GoInsureMe" />
          <StaticImage className="h-72 md:h-[800px] lg:h-[900px] xl:h-[1000px] col-span-4 lg:col-span-3 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/goinsureme-2.png" alt="GoInsureMe" />
        </div>
      </div>
      
    </motion.div>
    <CTA></CTA>
  </Layout>
)

export default GoInsureMe
